import Vue from 'vue'

const EXCEPT_PARAMS = [
  'utm_',
  'ref',
]

export default Vue.extend<any, any, any, any>({
  computed: {
    canonicalLink () {
      const url = this.getCanonicalUrl(this.$route)
      return {
        vmid: 'canonical',
        rel: 'canonical',
        href: url.href,
      }
    }
  },
  methods: {
    getCanonicalUrl (route: any, exceptParams: any) {
      const excepts = [...EXCEPT_PARAMS, ...(exceptParams || [])]
      const url = new URL(route.fullPath, process.env.VUE_APP_FRONTEND_URL)
      const params = new URLSearchParams(url.searchParams)
      for (const [key, value] of params.entries()) {
        if (excepts.some((par: string) => key.startsWith(par))) {
          url.searchParams.delete(key)
        }
      }
      return url
    },
  },
})

import Vue from 'vue'
import get from 'lodash/get'
import { mapState, mapActions, mapGetters } from 'vuex'
import canonicalMixin from '@/mixins/canonicalMixin'
import Skill from '@/models-ts/Skill'
import { FREELANCERS_LIST_BY_SKILL } from '@/constants/routes'
import { ISkillsState } from '@/store/shared/modules/skills/types'
import { RootState } from '@/store'

function getScreenWidth () {
  return Math.max(
    get(document, 'documentElement.clientWidth', 0),
    window.innerWidth
  )
}

export default Vue.extend<any, any, any, any>({
  mixins: [canonicalMixin],
  data () {
    return {
      loading: false,
      expandedSkills: [],
    }
  },
  computed: {
    ...mapState<RootState>('skills', {
      skillsData: (state: ISkillsState) => state.skills.value || [],
    }),
    ...mapGetters({
      getSkillChilds: 'skills/getChilds',
    }),
    skills () {
      const getUrl = (skill: Skill) => ({ name: FREELANCERS_LIST_BY_SKILL, params: { skill: skill.url } })
      const skills: Array<Skill> = this.skillsData
      return skills
        .filter(skill => (skill.relations?.Parent || []).length === 0)
        .map(skill => ({
          name: skill.name,
          url: getUrl(skill),
          childs: this.getSkillChilds(skill.id, 1).map((child: Skill) => ({ name: child.name, url: getUrl(child) })),
          shine: skill.name === 'Writing'
        }))
        .filter(skill => skill.childs.length)
        .sort((a, b) => b.childs.length - a.childs.length)
    }
  },
  async prefetch () {
    try {
      this.loading = true
      await this.getSkills()
      this.loading = false
    } catch (e) {
      console.error('Error fetching skills:', e)
    }
  },
  methods: {
    ...mapActions({
      getSkills: 'skills/getSkills',
    }),
    onClick (skill: any) {
      const TABLET_BP = 768
      if (getScreenWidth() > TABLET_BP) {
        this.$router.push(skill.url)
      } else {
        const index = this.expandedSkills.findIndex((s: string) => s === skill.name)
        if (index !== -1) {
          this.expandedSkills.splice(index, 1)
        } else {
          this.expandedSkills.push(skill.name)
        }
      }
    }
  },
  metaInfo () {
    return {
      title: 'Skills',
      link: [this.canonicalLink],
    }
  },
})
